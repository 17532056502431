<template>
  <main>
    <div>
      <section>
        <div class="contacts1">
          <div class="container">
            <div class="text-center">
              <h1>Contact Us</h1>
              <p style="color: black;">Any Question or remarks? Just write a message!</p>
              <br />
            </div>
          </div>
        </div>
      </section>
      <article class="dekContact">
        <section class="contacts2">
          <div class="container">
            <div class="contacts2div">
              <div>
                <div>
                  <h2 class="fs5 text-white">Contact Information</h2>
                  <p
                    class=""
                    style="color:#ada9a9;"
                  >Fill up the form and our team will get back to you within 24 hours.</p>

                  <p>
                    <a
                      style="color:grey;"
                      href="https://wa.me/+256772455423?text=Hello,AccessUgandaTours"
                    >
                      <i class="fab fa-whatsapp"></i> : +256 (0)702455423
                    </a>
                  </p>
                  <p>
                    <a
                      style="color:grey;"
                      href="tel:+256703807967"
                    >
                      <i class="fab fa-whatsapp"></i> : +256 (0)772455423
                    </a>
                  </p>
                  <p>
                    <a
                      style="color:grey;"
                      href="mailto:info@accessugandasafaris.com?subject=Hello, Lugave Safaris"
                    > <i class="fas fa-envelope"></i> : info@accessugandasafaris.com</a>
                  </p>
                  <p style="color:grey;"><i class="fas fa-map-marker-alt"></i> : National Theatre Plot 2,4,6 Dewinton Rd P.O.Box 26286 Kampala</p>
                  <p>
                    <a
                      style="color: grey; "
                      href="#"
                      target="_blank"
                      title="Facebook"
                    ><i class="fab fa-facebook-f"></i> </a>
                    <a
                      style="color: grey;  padding-left:10px"
                      href="3"
                      target="_blank"
                      title="Twitter"
                    ><i class="fab fa-twitter"></i> </a>
                    <a
                      style="color: grey; padding-left:10px"
                      href="#"
                      target="_blank"
                      title="Instagram"
                    ><i class="fab fa-instagram"></i></a>
                    <a
                      style="color: grey;  padding-left:10px"
                      href="#"
                      target="_blank"
                      title="Linkedin"
                    ><i class="fab fa-linkedin"> </i> </a>
                  </p>
                </div>
              </div>
              <div>
                <ContactFormComponent />
              </div>
            </div>
          </div>
        </section>
      </article>

      <article>
        <section>
          <div class="container">
            <div class="contact_grid">
              <div>
                <div>
                  <h2 class="fs5 text-white">Contact Information</h2>
                  <p>
                    <a
                      style="color:grey;"
                      href="https://wa.me/+256703807967/?text=Hello,LugaveSafaris"
                    >
                      <i class="fab fa-whatsapp"></i> : +256 (0)702455423
                    </a>
                  </p>
                  <p>
                    <a
                      style="color:grey;"
                      href="tel:+256703807967"
                    >
                      <i class="fab fa-whatsapp"></i> : +256 (0)772455423
                    </a>
                  </p>
                  <p>
                    <a
                      style="color:grey;"
                      href="mailto:info@accessugandasafaris.com?subject=Hello, Access Uganda Tours"
                    > <i class="fas fa-envelope"></i> : info@accessugandasafaris.com

                    </a>
                  </p>
                  <p style="color:grey;"><i class="fas fa-map-marker-alt"></i> : National Theatre Plot 2,4,6 Dewinton Rd</p>
                  <p>
                    <a
                      style="color: grey; "
                      href="#"
                      target="_blank"
                      title="Facebook"
                    ><i class="fab fa-facebook-f"></i> </a>
                    <a
                      style="color: grey;  padding-left:10px"
                      href="3"
                      target="_blank"
                      title="Twitter"
                    ><i class="fab fa-twitter"></i> </a>
                    <a
                      style="color: grey; padding-left:10px"
                      href="#"
                      target="_blank"
                      title="Instagram"
                    ><i class="fab fa-instagram"></i></a>
                    <a
                      style="color: grey;  padding-left:10px"
                      href="#"
                      target="_blank"
                      title="Linkedin"
                    ><i class="fab fa-linkedin"> </i> </a>
                  </p>
                </div>
              </div>
              <div>
                <ContactFormComponent />
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>

  </main>
</template>

<script>
import ContactFormComponent from "../components/contact-form-component.vue";
export default {
  components: {
    ContactFormComponent,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
label {
  margin-top: 20px;
  color: grey;
}
.contacts1 {
  padding-top: 100px;
}
.contacts2 {
  padding-bottom: 100px;
}
.contacts2div {
  position: relative;
  background-color: white;
  border-radius: 5px;
}
.contacts2div > div:first-child {
  position: absolute;
  left: 0px;
  width: 40%;
  background-color: #233a2e;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
  padding: 20px;
}
.contacts2div > div:nth-child(2) {
  margin-left: 40%;
  height: 100%;
  padding: 20px;
  background-color: #f0f9eb;
  border-radius: 0px 5px 5px 0px;
}
.dekContact {
  display: block;
}
.contact_grid {
  display: none;
}

.contacts1 h1 {
  font-size: 2em;
  font-weight: 600;
}

.contacts1 p {
  font-size: 1em;
  font-weight: 300;
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .dekContact {
    display: none;
  }
  .contact_grid {
    display: block;
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 5px;
    margin-bottom: 100px;
  }
  .contact_grid > div:first-child {
    background-color: #233a2e;
    padding: 20px;
    border-radius: 5px 5px 0px 0px;
  }
  .contact_grid > div:nth-child(2) {
    background-color: white;
    padding: 20px;
    border-radius: 0px 0px 5px 5px;
  }
  .contacts1 {
    padding-top: 100px;
  }
  .contacts2 {
    padding-bottom: 50px;
  }
  .contacts2div {
    position: relative;
    background-color: white;
    border-radius: 5px;
  }
  .contacts2div > div:first-child {
    position: absolute;
    left: 0px;
    width: 40%;
    background-color: #233a2e;
    height: 100%;
    border-radius: 5px 0px 0px 5px;
    padding: 20px;
  }
  .contacts2div > div:nth-child(2) {
    margin-left: 40%;
    height: 100%;
    padding: 20px;
  }

  .contacts1 h1 {
    font-size: 1.6em;
    font-weight: 600;
  }

  .contacts1 p {
    font-size: 1em;
    font-weight: 300;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .dekContact {
    display: none;
  }
  .contact_grid {
    display: block;
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 5px;
    margin-bottom: 100px;
  }
  .contact_grid > div:first-child {
    background-color: #233a2e;
    padding: 20px;
    border-radius: 5px 5px 0px 0px;
  }
  .contact_grid > div:nth-child(2) {
    background-color: white;
    padding: 20px;
    border-radius: 0px 0px 5px 5px;
  }
  .contacts1 {
    padding-top: 100px;
  }
  .contacts2 {
    padding-bottom: 50px;
  }
  .contacts2div {
    position: relative;
    background-color: white;
    border-radius: 5px;
  }
  .contacts2div > div:first-child {
    position: absolute;
    left: 0px;
    width: 40%;
    background-color: #233a2e;
    height: 100%;
    border-radius: 5px 0px 0px 5px;
    padding: 20px;
  }
  .contacts2div > div:nth-child(2) {
    margin-left: 40%;
    height: 100%;
    padding: 20px;
  }

  .contacts1 h1 {
    font-size: 1.6em;
    font-weight: 600;
  }

  .contacts1 p {
    font-size: 1em;
    font-weight: 300;
  }
}
</style>